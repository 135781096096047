import React from 'react'
import { ServicesContainer, ServicesH1, ServicesWrapper, ServicesCard, ServicesIcon, ServicesH2, ServicesP } from './ServicesElements'
import Icon1 from '../../images/undraw_cloud_hosting_aodd.svg'
import Icon2 from '../../images/undraw_programming_re_kg9v.svg'
import Icon3 from '../../images/undraw_collaborators_re_hont.svg'


const Services = () => {
    return (
        <ServicesContainer id="services">
            <ServicesH1>Our Services</ServicesH1>
            <ServicesWrapper>
                <ServicesCard>
                    <ServicesIcon src={Icon1}/>
                    <ServicesH2>Cloud Engineering</ServicesH2>
                    <ServicesP>We can help you with starting your cloud jurney or with enhancing your cloud native solution.</ServicesP>
                </ServicesCard>
                <ServicesCard>
                    <ServicesIcon src={Icon2}/>
                    <ServicesH2>Software Development</ServicesH2>
                    <ServicesP>We provide excellent software developement services from planing, developing and suppoting the application.</ServicesP>
                </ServicesCard>
                <ServicesCard>
                    <ServicesIcon src={Icon3}/>
                    <ServicesH2>IT Consulting</ServicesH2>
                    <ServicesP>If you need help with planning or managing your software projects. Our professional consultants are here to help!</ServicesP>
                </ServicesCard>
            </ServicesWrapper>
        </ServicesContainer>
    )
}

export default Services
