import React, {useState} from 'react';
import { HeroContainer, HeroBg, VideoBg } from './HeroElements';
import Video from '../../videos/video_2.mp4';
import {HeroContent, HeroH1, HeroP, HeroBtnWrapper, ArrowForward, ArrowRight } from './HeroElements';
import {Button} from '../ButtonElements'

const HeroSection = () => {
    const [hover, setHover] = useState(false)

    const onHover = () => {
        setHover(!hover)
    }

    return (
        <HeroContainer id='home'>
            <HeroBg>
                <VideoBg autoPlay loop muted src={Video} type='video/mp4' />
            </HeroBg>
            <HeroContent>
                <HeroH1>Software Developement Made Easy</HeroH1>
                <HeroP>
                    You define the project requirements and we deliver the software solutions.
                </HeroP>
                <HeroBtnWrapper>
                    <Button to="signup" onMouseEnter={onHover} onMouseLeave={onHover} 
                        primary='true'
                        dark='true'
                        smooth={true} duration={500} spy={true} exact={true} offset={-80}
                    > 
                        Get started {hover ? <ArrowForward/> : <ArrowRight/>}
                    </Button>
                </HeroBtnWrapper>
            </HeroContent>
        </HeroContainer>
    )
}

export default HeroSection
