import g1 from "../../images/undraw_engineering_team_u99p.svg"
import g2 from "../../images/undraw_good_team_re_j1kc.svg"
import g3 from "../../images/undraw_right_direction_tge8.svg"


export const homeObjOne = {
    id: 'about',
    lightBg: true,
    lightText: false,
    lightTextDesc: false,
    topLine: 'Agile Software Developement',
    headline: 'Our team provides professional software solutions.',
    description: 'We know that the client needs can change during the project. Our goal is to deliver high quality applications for our partners with evolving needs.',
    buttonLabel: 'Contact us',
    imgStart: true,
    img: g1,
    alt: 'Car',
    dark: false,
    primary: false,
    darkText: true,
    buttonEnabled: true
};

export const homeObjTwo = {
    id: 'discover',
    lightBg: true,
    lightText: false,
    lightTextDesc: false,
    topLine: 'Unlimited Access',
    headline: 'Login to your account at any time',
    description: 'We have you covered no matter where you are located.  All you need is an internet connection and a phone or computer.',
    buttonLabel: 'Where to find us',
    imgStart: true,
    img: g2,
    alt: 'Piggybank',
    dark: false,
    primary: false,
    darkText: true
};

export const homeObjThree = {
    id: 'signup',
    lightBg: true,
    lightText: false,
    lightTextDesc: false,
    topLine: 'Contact us',
    headline: 'If you would like to work with us do not hesitate to contact us.',
    description: "Get everything set up and ready in under 10 minutes. All you need to do is add your information and you're ready to go",
    buttonLabel: 'Start Now',
    imgStart: false,
    img: g3,
    alt: 'Papers',
    dark: false,
    primary: false,
    darkText: true
};