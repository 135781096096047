import React, {useState} from 'react'
import Sidebar from '../components/Sidebar'
import NavBar from '../components/NavBar'
import HeroSection from '../components/HeroSection';
import InfoSection from '../components/InfoSection';
import ContactUs from '../components/ContactUs';
import { homeObjOne, homeObjTwo } from '../components/InfoSection/Data';
import { homeObjThree } from '../components/ContactUs/Data';
import Services from '../components/Services'
import Footer from '../components/Footer';

const Home = () => {
    const [isOpen, setIsOpen] = useState(false);

    const toggle = () => {
        setIsOpen(!isOpen)
    }

    return (
        <>
            <Sidebar isOpen={isOpen} toggle={toggle}/>
            <NavBar toggle={toggle}/>
            <HeroSection />
            <InfoSection {...homeObjOne}/>
            {/* <InfoSection {...homeObjTwo}/> */}
            <Services/>
            <ContactUs {...homeObjThree}/>
            <Footer/>
        </>
    )
}

export default Home
