import React from 'react'
import { Button } from '../ButtonElements'
import { InfoContainer, InfoWrapper, InfoRow, Column1, TextWrapper, TopLine, Heading, Subtitle, BtnWrap, Column2, ImgWrap, Img } from './ContactUsElements'

const CuntactUsSection = ({ id, lightBg, imgStart, topLine, headline, 
    lightText, description, darkText, buttonLabel, img, alt, primary ,dark, dark2, buttonEnabled}) => {
    return (
        <>
            <InfoContainer lightBg={lightBg} id={id}>
                <InfoWrapper>
                    <InfoRow imgStart={imgStart}>
                        <Column1>
                            <TextWrapper>
                                <TopLine>
                                    {topLine}
                                </TopLine>
                                <Heading lightText={lightText}>{headline}</Heading>
                                <Subtitle darkText={darkText}>
                                <ul>
                                    <li>
                                        Name: Gekcode Ltd
                                    </li>
                                </ul>
                                <ul>
                                    <li>
                                        Company Number: 14121430
                                    </li>
                                </ul>
                                <ul>
                                    <li>
                                        Address: Gekcode Ltd., 124 City Road, London, England, EC1V 2NX
                                    </li>
                                </ul>
                                <ul>
                                    <li>
                                        E-mail: info@gekcode.co.uk
                                    </li>
                                </ul>
                                </Subtitle>
                                {buttonEnabled ? 
                                <BtnWrap>
                                    <Button to="signup"
                                        smooth={true}
                                        duration={500}
                                        spy={true}
                                        exact="true"
                                        offset={-80}
                                        primary={primary ? 1 : 0}
                                        dark={dark ? 1 : 0}
                                        dark2={dark2 ? 1 : 0}
                                    > {buttonLabel}</Button>
                                </BtnWrap>
                                    : <></>
                                }
                            </TextWrapper>
                        </Column1>
                        <Column2>
                            <ImgWrap>
                                <Img src={img} alt={alt} />
                            </ImgWrap>
                        </Column2>
                    </InfoRow>
                </InfoWrapper>
            </InfoContainer>
        </>
    )
}

export default CuntactUsSection
